"use client";

import React, { useState, useRef, useEffect } from "react";
import {
  Receipt,
  FileX2,
  Calendar,
  Bike,
  Wallet,
  FileCheck,
  FileUp,
  Check,
} from "lucide-react";
import * as Switch from "@radix-ui/react-switch";
import "../../../../styles/DialogStyles.css";
import { useSubscriptions } from "../../../../context/SubscriptionsContext";
import {
  toMayus,
  getStateName,
  timeSince,
  formatDateTime,
} from "../../../../scripts/generalScript";
import ConfirmationButton from "../../../ui/ConfirmationButton";
import DatePickerComponent from "../../../ui/DatePickerComponent";
import { useNavigate } from "react-router-dom";
import { useSupaContext } from "../../../../context/SupaContext";

export default function Component({
  type = "activate",
  orderDataDummy = {},
  onClose = () => {},
  subscription = {},
}) {
  const {
    getOrderById,
    processOrderItems,
    cancelSubscriptionsByOrder,
    cancelOrder,
    cancelMultipleSubscriptions,
    cancelRefillsByOrder,
    activeOrder,
    activeSubscriptionsByOrder,
    activeRefillsByOrder
  } = useSubscriptions();

  const{migratePreRegistrationToUser} = useSupaContext()

  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState("digital");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("morning");
  const [orderData, setOrderData] = useState({});
  const [orderItems, setOrderItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [validationOrder, setValidationOrder] = useState(false);
  const [validationSugOps, setValidationsSugOps] = useState(false);
  const [validationAction, setValidationAction] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [fileState, setFileState] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchOrderData = async () => {
      const data = await getOrderById(subscription.order_id);
      if (data[0].id) {
        const orderItems = await processOrderItems(data[0].id);
        if (orderItems) {
          setOrderData(data);
          setOrderItems(orderItems);
          setIsLoading(false);
        }
      }
    };

    fetchOrderData();
  }, [subscription.id]);

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 justify-center items-center p-16">
        Cargando...
      </div>
    );
  }

  const handleFileUpload = (file) => {
    if (!file) return;

    const validTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validTypes.includes(file.type)) {
      alert("Solo se permiten archivos .jpg, .jpeg, .png o .pdf");
      resetFileInput();
      return;
    }

    if (file.size > maxSize) {
      alert("El archivo debe ser menor a 5MB");
      resetFileInput();
      return;
    }

    setFileState(file);
    console.log("Archivo válido:", file.name);
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setFileState(null);
  };

  const isAllValidated = () => {
    const baseValidations =
      validationAction && validationOrder && validationSugOps;
    return paymentMethod === "digital"
      ? baseValidations && fileState
      : baseValidations;
  };

  // --------------------------------------
  // Método para cancelar la suscripcion
  // --------------------------------------
  const handleValidatedCancellation = async () => {
    try {
      await cancelOrder(orderData[0].id);
      const result = {};
      orderItems.orderItems.forEach((item) => {
        if (!result[item.item_type]) {
          result[item.item_type] = [];
        }
        result[item.item_type].push(item.item_id);
      });
      try {
        await cancelSubscriptionsByOrder(orderData[0].id);
        await cancelRefillsByOrder(orderData[0].id);
      } catch (error) {
        console.log("Error cancelando subscription:", error);
      }
    } catch (error) {
      console.log("Error cancelando orden:", error);
    } finally {
      navigate("/dashboard/suscripciones/" + subscription.shortid);
      window.location.reload();
    }
  };

  // --------------------------------------
  // Método para activar la suscripcion
  // --------------------------------------
  const handleValidatedActivation = async () => {
    try {
      await activeOrder(orderData[0].id);
      const result = {};
      orderItems.orderItems.forEach((item) => {
        if (!result[item.item_type]) {
          result[item.item_type] = [];
        }
        result[item.item_type].push(item.item_id);
      });
      try {
        await activeSubscriptionsByOrder(orderData[0].id);
        try {
          await activeRefillsByOrder(orderData[0].id);
          try {
            await migratePreRegistrationToUser(orderData[0].user_id)
          } catch (error) {
            console.log("Error moviendo usuario:", error);
          }
        } catch (error) {
          console.log("Error activando recambios:", error);
        }
      } catch (error) {
        console.log("Error activando subscription:", error);
      }
    } catch (error) {
      console.log("Error cancelando orden:", error);
    } finally {
      // navigate("/dashboard/suscripciones/"+subscription.shortid)
      // window.location.reload();
    }
  };

  const renderOrderSummary = () => (
    <>
      {Object.keys(orderItems.summary.byType).map((type) => {
        const typeData = orderItems.summary.byType[type];
        const unitValue =
          orderItems.orderItems.find((item) => item.item_type === type)
            ?.unitValue || 0;

        return (
          <div key={type} className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <span className="text-md">
                {type === "individual_refill" ? "Recargas" : "Suscripciones"}
              </span>
              <div className="px-1 py-0.5 bg-neutral-200 rounded">
                <span className="text-md font-bold text-neutral-800">
                  x{typeData.qty} - ${unitValue.toLocaleString()}
                </span>
              </div>
            </div>
            <span className="text-md">
              ${typeData.totalValue.toLocaleString()}
            </span>
          </div>
        );
      })}
      <div className="flex justify-between items-center">
        <span className="text-md font-bold text-neutral-800">
          Total de pedido
        </span>
        <span className="text-md font-bold text-neutral-800">
          ${orderItems.summary.totalAmount.toLocaleString()}
        </span>
      </div>
    </>
  );

  const renderPaymentSection = () => (
    <>
      <label className="flex items-center gap-2 text-md font-medium">
        <Wallet className="w-4 h-4" />
        Elegir metodo de pago
      </label>
      <div className="flex gap-2 p-2 bg-white rounded-2xl border border-zinc-200">
        {["digital", "cash"].map((method) => (
          <button
            key={method}
            onClick={() => setPaymentMethod(method)}
            className={`flex-1 px-2 py-1 rounded-xl text-md text-center ${
              paymentMethod === method
                ? method === "digital"
                  ? "bg-[#344d7d] text-white"
                  : "bg-green-600 text-white"
                : "text-black"
            }`}
          >
            {method === "digital" ? "Pago digital" : "Pago en efectivo"}
          </button>
        ))}
      </div>
      {paymentMethod === "digital" && (
        <div className="flex flex-col gap-2">
          <label className="flex items-center gap-2 text-md font-medium">
            <FileUp className="w-4 h-4" />
            Subir comprobante de pago
          </label>
          <div className="flex gap-2 p-2 bg-white rounded-2xl border border-zinc-200">
            <input
              ref={fileInputRef}
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              className="file:mr-2 file:py-1 file:px-4 file:rounded-lg file:border-0 file:text-sm file:bg-[#344d7d] file:text-white file:cursor-pointer bg-neutral-50 border-2 border-slate-600 rounded-lg text-slate-600 text-sm w-full"
              onChange={(e) => handleFileUpload(e.target.files[0])}
            />
            {fileState !== null && <Check color="#1cca3e" />}
          </div>
        </div>
      )}
    </>
  );

  const renderSubscriptionSection = () => (
    <div className="bg-neutral-50 rounded-3xl border border-zinc-200 p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1">
          <span className="text-md font-bold text-neutral-800 flex items-center gap-1">
            {type === "cancel" ? (
              <FileX2 className="w-5 h-5" />
            ) : (
              <FileCheck className="w-5 h-5" />
            )}
            {type === "cancel" ? "Cancelar suscripción" : "Activar suscripción"}
          </span>
        </div>
        <div className="px-2 py-1 bg-sky-100">
          <span className="text-md font-bold text-neutral-800">
            {toMayus(subscription.shortid)}
          </span>
        </div>
      </div>
      <div className="my-4 h-px bg-zinc-100" />
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <div className="text-md">
            {type === "cancel"
              ? "Retornar botellón reservado a inventario"
              : "Mover botellón reservado a producción"}
          </div>
          <Switch.Root
            className="SwitchRoot"
            onClick={() => setValidationsSugOps(!validationSugOps)}
          >
            <Switch.Thumb className="SwitchThumb" />
          </Switch.Root>
        </div>
        <div className="flex justify-between items-center py-2">
          <div className="text-md">
            {type === "cancel"
              ? 'Cancelar suscripción, motivo "Pago no completado"'
              : "Activar suscripción"}
          </div>
          <Switch.Root
            className="SwitchRoot"
            onClick={() => setValidationAction(!validationAction)}
          >
            <Switch.Thumb className="SwitchThumb" />
          </Switch.Root>
        </div>
      </div>
    </div>
  );

  const renderDeliverySection = () => (
    <div className="bg-neutral-50 rounded-3xl border border-zinc-200 p-4">
      <div className="flex items-center gap-1">
        <Bike className="w-5 h-5" />
        <span className="text-md font-bold text-neutral-800">Entrega</span>
      </div>
      <div className="my-4 h-px bg-zinc-100" />
      <div className="flex gap-2">
        <div className="flex-1 p-2 bg-white rounded-2xl border border-zinc-200 flex items-center gap-2 min-w-1/3 max-w-1/3">
          <DatePickerComponent
            setDeliveryDate={setDeliveryDate}
            limitTodayTime={9}
          />
        </div>
        <div className="flex gap-2 p-2 bg-white rounded-2xl border border-zinc-200 w-100">
          {["morning", "afternoon"].map((slot) => (
            <div
              key={slot}
              onClick={() => setSelectedTimeSlot(slot)}
              className={`rounded-xl text-md text-center p-0 m-0 flex flex-col px-2 items-center justify-center w-full py-2 ${
                selectedTimeSlot === slot
                  ? "bg-[#344d7d] text-white"
                  : "text-black"
              }`}
            >
              <p className="p-0 m-0 text-nowrap font-semibold">
                {slot === "morning" ? "Por la mañana" : "Por la tarde"}
              </p>
              <p className="text-xs p-0 mt-[-4px]">
                {slot === "morning" ? "8am - 12pm" : "1pm - 7pm"}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="bg-neutral-50 rounded-3xl border border-zinc-200 p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <Receipt className="w-5 h-5" />
            <span className="text-md font-bold text-neutral-800">
              {type === "cancel"
                ? "Existe un pedido pendiente"
                : "Validar pedido pendiente"}
            </span>
          </div>
          <div className="px-2 py-1 bg-gray-800">
            <span className="text-md font-bold text-neutral-50">
              {orderData[0].public_id}
            </span>
          </div>
        </div>
        <div className="my-4 h-px bg-zinc-100" />
        <div className="flex w-full flex-col">
          <span className="text-md font-bold">{orderDataDummy.customer}</span>
          <span className="text-sm text-neutral-600">
            {orderDataDummy.address}
          </span>
        </div>
        <div className="my-4 h-px bg-zinc-100" />
        <div className="space-y-2.5">{renderOrderSummary()}</div>
        <div className="h-px mt-4 bg-zinc-100" />
        {type === "activate" && (
          <div className="mt-4 space-y-4">
            {renderPaymentSection()}
            <div className="h-px bg-zinc-100" />
            <div className="flex justify-between items-center">
              <span className="text-md font-bold">
                Validar pedido {toMayus(orderData[0].public_id)}
              </span>
              <Switch.Root
                className="SwitchRoot"
                onClick={() => setValidationOrder(!validationOrder)}
              >
                <Switch.Thumb className="SwitchThumb" />
              </Switch.Root>
            </div>
          </div>
        )}
        {type === "cancel" && (
          <div className="flex justify-between items-center">
            <span className="text-md font-bold">
              Cancelar pedido pendiente {toMayus(orderData[0].public_id)}
            </span>
            <Switch.Root
              className="SwitchRoot"
              onClick={() => setValidationOrder(!validationOrder)}
            >
              <Switch.Thumb className="SwitchThumb" />
            </Switch.Root>
          </div>
        )}
      </div>

      {renderSubscriptionSection()}

      {type === "activate" && renderDeliverySection()}

      <div className="flex justify-around items-center pt-2 w-full gap-2">
        <button className="button-secondary w-full" onClick={onClose}>
          {type === "activate"
            ? "No activar suscripción"
            : "No cancelar suscripción"}
        </button>
        <ConfirmationButton
          initialLabel={
            type === "activate" ? "Activar suscripción" : "Cancelar suscripción"
          }
          onConfirm={
            type === "activate"
              ? handleValidatedActivation
              : handleValidatedCancellation
          }
          loadingTime={type === "activate" ? 3000 : 2000}
          state={type === "activate" ? "default" : "error"}
          disabled={
            type === "activate"
              ? !isAllValidated()
              : !(validationAction && validationOrder && validationSugOps)
          }
        />
      </div>
    </div>
  );
}
