export function formatDateTime(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('es-ES', { month: 'short' });
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const dayOfWeek = date.toLocaleString('es-ES', { weekday: 'short' });
    return toMayus(`${year}/${month}/${day} ${hour}:${minute} ${dayOfWeek}`);
  }


 export function toMayus(str) {
    return str.toUpperCase();
  }
  

  export function getStateName(stateCode) {
    switch (stateCode) {
      case 'deal_pending':
        return 'Pago pendiente';
      case 'INA':
        return 'Inactivo';
      case 'PEN':
        return 'Pendiente';
      case 'canceled':
        return 'Cancelada';
      case 'active':
        return 'Activa';
      default:
        return stateCode;
    }
  }
  
  const stateCode = 'ACT';
  const stateName = getStateName(stateCode);
  console.log(stateName); // Output: "Activo"

  export function timeSince(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
  
    if (months > 0) {
      return `${months}m`;
    } else if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      if (hours < 1) {
        return "0h";
      } else {
        return `${hours}h`;
      }
    } else {
      return "0h";
    }
  }

  export const formatDate = (dateString) => {
    if (!dateString) return "Sin definir";
    return new Date(dateString).toLocaleDateString("es-CO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  export const generatePassword = ()=>  {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.';
    let password = '';
    for (let i = 0; i < 12; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return password;
  }
  
  console.log(generatePassword()); // Ejemplo de salida: "4a8bC3dE2fG."