import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

const Index = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/ingresar')
    }, 3000)

    return () => clearTimeout(timeout)
  }, [navigate])

  return (
    <motion.div
      className='min-h-screen min-w-screen flex items-center justify-center'
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, y: -100 }}
      transition={{ duration: 0.8 }}
    >
      <img 
        src='assets/logos/fluenti-logo-blue-140.svg' 
        alt='Fluenti' 
        className='h-16' 
      />
    </motion.div>
  )
}

export default Index
