import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Search, ChevronLeft, ChevronRight, X } from "lucide-react";
import { Button, Table } from "@radix-ui/themes";
import { useSubscriptions } from "../../../context/SubscriptionsContext";
import RightSidebar from "../../ui/RightSidebar";
import { formatDate } from "../../../scripts/generalScript";
import * as Tabs from "@radix-ui/react-tabs";

import {
  statusLabels,
  statusColors,
  filterTabs,
} from "../../../scripts/constants";
import SearchAndPaginator from "./AdminSubscriptionsContent/SearchAndPaginator";
import SubscriptionsTabs from "./AdminSubscriptionsContent/SubscriptionsTabs";

const AdminSusciptions = () => {
  
  const params = useParams();
  const short_id= params.shortId
  
  const { subscriptions, loading, error, fetchSubscriptions } =
    useSubscriptions();
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sidebarState, setSidebarState] = useState({
    isOpen: false,
    status: null,
  });
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const filteredSubscriptions = subscriptions.filter((sub) => {
    const matchesTab = activeTab === "all" || sub.status === activeTab;
    const matchesSearch =
      searchTerm === "" ||
      sub.shortid.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesTab && matchesSearch;
  });

  // Función para abrir el sidebar con un estado específico
  const openSidebar = (status, subscription) => {
    setSidebarState({
      isOpen: true,
      status,
    });
    setSelectedSubscription(subscription);
  };

  // Función para cerrar el sidebar
  const closeSidebar = () => {
    setSidebarState({
      isOpen: false,
      status: null,
    });
    setSelectedSubscription(null);
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    if (short_id && subscriptions.length > 0) {
      const matchingSubscription = subscriptions.find(
        (sub) => sub.shortid.toLowerCase() === short_id.toLowerCase()
      );
      if (matchingSubscription) {
        openSidebar("A", matchingSubscription);
      }
    }
  }, [short_id, subscriptions]);

  if (loading)
    return (
      <div className="min-h-screen bg-stone-50 p-6">
        Cargando suscripciones...
      </div>
    );
  if (error)
    return <div className="min-h-screen bg-stone-50 p-6">Error: {error}</div>;

  const getStatusCounts = () => {
    const counts = subscriptions.reduce((acc, sub) => {
      acc[sub.status] = (acc[sub.status] || 0) + 1;
      return acc;
    }, {});
    counts.all = subscriptions.length;
    return counts;
  };

  const statusCounts = getStatusCounts();

  return (
    <div className="min-h-screen bg-stone-50 p-6">
      <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
        <Tabs.List className="flex flex-wrap gap-2 mb-6">
          {filterTabs.map((tab) => (
            <Tabs.Trigger
              key={tab.key}
              value={tab.key}
              className={`px-4 py-2 rounded-lg text-sm font-semibold transition-colors ${
                activeTab === tab.key ? tab.activeClasses : tab.inactiveClasses
              }`}
            >
              <span>{tab.label}</span>
              <span
                className={`ml-2 px-2 py-1 rounded-lg text-xs font-bold inline-flex items-center justify-center ${
                  activeTab === tab.key ? tab.countClasses : tab.activeClasses
                }`}
              >
                {statusCounts[tab.key] || 0}
              </span>
            </Tabs.Trigger>
          ))}
          <div className="ml-auto">
            <Button size="3" className="ml-auto">
              Nueva suscripción
            </Button>
          </div>
        </Tabs.List>
      </Tabs.Root>
      <div className="bg-white rounded-3xl border border-zinc-100 overflow-hidden">
        <SearchAndPaginator
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          subscriptions={subscriptions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          activeTab={activeTab}
        />
        <Table.Root
          variant="ghost"
          className="w-full overflow-x-scroll shadow-xl bg-white"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Suscripción</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell className="max-w-[300px]">
                Cliente
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Estado</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Renovación</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell className="text-center">
                Realizadas
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell className="text-center">
                Corte
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Botellón</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell className="text-center">
                Recambios
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Dispensador</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredSubscriptions.map((subscription, index) => (
              <Table.Row
                key={subscription.id}
                onClick={() => openSidebar("A", subscription)}
                className={`${
                  index % 2 === 0 ? "bg-white" : "bg-slate-50"
                } items-center hover:scale-[1.01] hover:bg-blue-100 transition-transform duration-10 cursor-pointer`}
              >
                <Table.RowHeaderCell>
                  <div className="font-semibold flex items-center h-full">
                    {subscription.shortid.toUpperCase()}
                  </div>
                </Table.RowHeaderCell>
                <Table.Cell className="max-w-[240px]">
                  <div className="text-ellipsis whitespace-nowrap overflow-hidden text-gray-900 font-semibold ">
                    {subscription.first_name + " " + subscription.last_name ||
                      "Verificar dirección."}
                  </div>
                  <div className="text-ellipsis whitespace-nowrap overflow-hidden text-gray-600">
                    {subscription.address || "Verificar dirección."}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="flex items-center h-full justify-center">
                    <span
                      className={`px-2 py-1 rounded text-white text-xs font-semibold ${
                        statusColors[subscription.status]
                      }`}
                    >
                      {statusLabels[subscription.status]}
                    </span>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="flex items-center h-full">
                    {formatDate(subscription.next_renewal_date) ||
                      "Por definir"}
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center">
                  <div className="flex items-center h-full">
                    {subscription.renovations_counter || 0}
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center">
                  <div className="flex items-center h-full">
                    {subscription.billing_day || "na"}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="font-semibold flex items-center h-full">
                    {subscription.codebar}
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center">
                  <div className="flex items-center h-full">
                    {subscription.refills_counter || 0}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="font-semibold flex items-center h-full">
                    00000000
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </div>
      <RightSidebar
        isOpen={sidebarState.isOpen}
        onClose={closeSidebar}
        selectedSubscription={selectedSubscription}
      />
    </div>
  );
};

export default AdminSusciptions;
