"use client";

import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  LayoutDashboard,
  Ticket,
  Bike,
  Settings2,
  Users,
  Package,
  Factory,
  Building2,
  Receipt,
  UserCog,
  Store,
} from "lucide-react";

const menuItems = [
  { path: "/dashboard", icon: LayoutDashboard, label: "Resumen" },
  { path: "/dashboard/suscripciones", icon: Ticket, label: "Suscripciones" },
  { path: "/dashboard/pedidos", icon: Bike, label: "Pedidos y entregas" },
  { path: "/dashboard/ops", icon: Store, label: "Operación" },
  { path: "/dashboard/clientes", icon: Users, label: "Clients" },
  { path: "/dashboard/inventario", icon: Package, label: "Inventario" },
  { path: "/dashboard/proveedores", icon: Factory, label: "Proveedores" },
  { path: "/dashboard/plantas", icon: Building2, label: "Plantas" },
  { path: "/dashboard/finanzas", icon: Receipt, label: "Finanzas" },
  { path: "/dashboard/ajustes", icon: Settings2, label: "Ajustes" },
  { path: "/dashboard/cuenta", icon: UserCog, label: "Cuenta de usuario" },
];

const SidebarLink = ({
  to,
  icon: Icon,
  children,
  isMobile,
  sidebarOpen,
  onMobileClick,
}) => {
  const isActive = useLocation().pathname === to;

  return (
    <Link
      to={to}
      className={`flex items-center no-underline space-x-2 px-2 py-3 rounded-lg transition-colors duration-200 ${
        isActive
          ? "bg-blue-50 text-blue-800 font-bold shadow-md"
          : "text-white hover:bg-blue-500"
      }`}
      onClick={() => isMobile && onMobileClick?.()}
    >
      <div className="w-5 h-5 flex items-center justify-center">
        <Icon className="w-4 h-4" />
      </div>
      {(!isMobile || sidebarOpen) && (
        <span className="text-sm font-['Nunito Sans']">{children}</span>
      )}
    </Link>
  );
};

const DashboardSidepanel = ({ isMobile, sidebarOpen, onMobileClick }) => {
  return (
    <aside
      className={`${
        isMobile
          ? sidebarOpen
            ? "translate-x-0 w-56"
            : "-translate-x-full w-20"
          : sidebarOpen
          ? "w-64"
          : "w-20"
      } transform transition-all duration-300 ease-in-out
          fixed md:static inset-y-0 left-0 z-30
          bg-blue-700 flex flex-col shadow-2xl
        `}
    >
      {/* Logo Section */}
      <div className="py-6 flex flex-col items-center justify-center">
        <div className={`${sidebarOpen ? "w-36 h-14" : "w-16 h-9"} relative`}>
          <img
            src="assets/logos/fluenti-logo-black-140.svg"
            alt="Fluenti"
            height="56"
          />
        </div>
      </div>

      {/* Divider */}
      <div className="h-px border-t border-blue-400" />

      {/* Navigation Items */}
      <nav className="flex-1 px-6 py-4 space-y-2 overflow-y-auto">
        {menuItems.map((item) => (
          <SidebarLink
            key={item.path}
            to={item.path}
            icon={item.icon}
            isMobile={isMobile}
            sidebarOpen={sidebarOpen}
            onMobileClick={onMobileClick}
          >
            {item.label}
          </SidebarLink>
        ))}
      </nav>
    </aside>
  );
};

export default DashboardSidepanel;
