import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Search, X } from 'lucide-react';
import { motion, AnimatePresence } from "framer-motion";

const SearchAndPaginator = ({
  initialSearchTerm = "",
  initialFilteredSubscriptions = [],
  subscriptions = [],
  initialCurrentPage = 1,
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState(initialFilteredSubscriptions);
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    // Validar que los datos necesarios estén disponibles
    if (Array.isArray(subscriptions) && subscriptions.length > 0) {
      setIsDataReady(true);
    } else {
      setIsDataReady(false);
    }
  }, [subscriptions]);

  useEffect(() => {
    // Filtrar suscripciones basado en el término de búsqueda
    const filtered = subscriptions.filter(sub =>
      sub && sub.name && sub.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSubscriptions(filtered);
    setCurrentPage(1); // Resetear a la primera página cuando cambia la búsqueda
  }, [searchTerm, subscriptions]);

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredSubscriptions.length / 10)));
  };

  if (!isDataReady) {
    return null; // No renderizar nada si los datos no están listos
  }

  return (
    <AnimatePresence>
      {isDataReady && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <div className="p-6 flex flex-col sm:flex-row justify-between items-center border-b border-zinc-100">
            <div className="flex items-center gap-4 bg-gray-50 rounded-lg px-2 mb-4 sm:mb-0">
              <Search size={20} />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Buscar suscripción"
                className="pr-4 py-2 border rounded-lg"
              />
              {searchTerm !== "" && (
                <button onClick={() => setSearchTerm("")} aria-label="Limpiar búsqueda">
                  <X size={12} />
                </button>
              )}
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm font-semibold">
                Mostrando {filteredSubscriptions.length} de {subscriptions.length}
              </span>
              <div className="flex items-center space-x-2">
                <button
                  onClick={handlePrevPage}
                  className="p-2 rounded-lg border border-zinc-100"
                  disabled={currentPage === 1}
                  aria-label="Página anterior"
                >
                  <ChevronLeft size={16} />
                </button>
                <span className="text-sm font-semibold">{currentPage}</span>
                <button
                  onClick={handleNextPage}
                  className="p-2 rounded-lg border border-zinc-100"
                  disabled={filteredSubscriptions.length <= currentPage * 10}
                  aria-label="Página siguiente"
                >
                  <ChevronRight size={16} />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SearchAndPaginator;

