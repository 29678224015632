import React, { useContext, useEffect, useState } from "react";
import { SupaContext } from "../../../../../context/SupaContext";

const SubscriptionAddressInfo = (props) => {
  const { getPublicFileUrl } = useContext(SupaContext); // Obtén el método del contexto
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (props.subscription.address_id) {
        const url = await getPublicFileUrl(props.subscription.address_id);
        setImageUrl(url); // Actualiza la URL solo si es válida
      }
    };

    fetchImageUrl();
  }, [props.subscription.address_id, getPublicFileUrl]);

  return (
    <div className="AddressInfo self-stretch h-24 flex flex-col justify-start items-start gap-6">
      <div className="TitleTag self-stretch h-7 flex flex-col justify-start items-start gap-2.5">
        <div className="Cell px-2 py-1 bg-sky-100 flex justify-start items-center gap-4">
          <div className="Title text-neutral-800 text-base font-bold">
            Dirección de entrega
          </div>
        </div>
      </div>
      <div className="Address self-stretch py-1 flex flex-col gap-4 justify-between items-center">
        <div className="Direccion text-black text-md font-semibold">
          {props.subscription.address} Ver en mapa
        </div>
      {imageUrl && (
        <div className="rounded-xl overflow-hidden mb-16">
          <img
            src={imageUrl}
            alt="Dirección captura"
            className="self-stretch  object-contain w-full"
          />
        </div>
      )}
      </div>
      {/* Renderizar imagen si existe */}
    </div>
  );
};

export default SubscriptionAddressInfo;
