import "./App.css";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";
import { SupaProvider } from "./context/SupaContext";
import { SubscriptionsProvider } from "./context/SubscriptionsContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/core/PrivateRoute";
import Index from "./components/pages/Index";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/pages/Login";

function App() {
  return (
    <Theme panelBackground="solid" radius="large">
      <BrowserRouter>
        <SupaProvider>
          <SubscriptionsProvider>
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/ingresar" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/*"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
            </Routes>
          </SubscriptionsProvider>
        </SupaProvider>
      </BrowserRouter>
    </Theme>
  );
}

export default App;
