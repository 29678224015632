export const statusColors = {
    deal_pending: "bg-fuchsia-600",
    active: "bg-green-500",
    renewal_pending: "bg-amber-500",
    return_pending: "bg-neutral-800",
    canceled: "bg-red-700",
  };
  
  export const statusLabels = {
    deal_pending: "Pago pendiente",
    active: "Activa",
    renewal_pending: "Renovación",
    return_pending: "Retorno",
    canceled: "Cancelada",
  };

  export const filterTabs = [
    {
      key: "all",
      label: "Todas",
      inactiveClasses: "bg-blue-100 text-neutral-800 border-0",
      activeClasses: "bg-blue-600 text-white border-0",
      countClasses: "bg-blue-700 text-white",
    },
    {
      key: "deal_pending",
      label: "Pago pendiente",
      inactiveClasses: "bg-fuchsia-100 text-neutral-800 border-0",
      activeClasses: "bg-fuchsia-600 text-white border-0",
      countClasses: "bg-purple-800 text-white",
    },
    {
      key: "renewal_pending",
      label: "Renovación",
      inactiveClasses: "bg-orange-50 text-neutral-800 border-0",
      activeClasses: "bg-amber-500 text-white border-0",
      countClasses: "bg-yellow-700 text-white",
    },
    {
      key: "active",
      label: "Activas",
      inactiveClasses: "bg-green-100 text-neutral-800 border-0",
      activeClasses: "bg-green-500 text-white border-0",
      countClasses: "bg-green-800 text-white",
    },
    {
      key: "return_pending",
      label: "Retorno",
      inactiveClasses: "bg-gray-200 text-neutral-800 border-0",
      activeClasses: "bg-neutral-800 text-white border-0",
      countClasses: "bg-white text-neutral-800",
    },
    {
      key: "canceled",
      label: "Canceladas",
      inactiveClasses: "bg-rose-100 text-neutral-800 border-0",
      activeClasses: "bg-red-700 text-white border-0",
      countClasses: "bg-red-800 text-white",
    },
  ];
  