import React from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import DealPendingDetails from '../dashboard/components-dashboard/subscriptions/DealPendingDetails';
import CancelledDetails from '../dashboard/components-dashboard/subscriptions/CancelledDetails';
import ActiveDetails from '../dashboard/components-dashboard/subscriptions/ActiveDetails';

// Componentes de ejemplo para diferentes estados
const ComponentA = () => (
  <div className="p-6">
    <h2 className="text-xl font-semibold mb-4">Componente A</h2>
    <div className="space-y-4">
      <div className="bg-blue-50 p-4 rounded-lg">
        <h3 className="font-medium mb-2">Sección 1</h3>
        <p className="text-gray-600">Contenido de la sección 1</p>
      </div>
      <div className="bg-blue-50 p-4 rounded-lg">
        <h3 className="font-medium mb-2">Sección 2</h3>
        <p className="text-gray-600">Contenido de la sección 2</p>
      </div>
    </div>
  </div>
);

const ComponentB = () => (
  <div className="p-6">
    <h2 className="text-xl font-semibold mb-4">Componente B</h2>
    <form className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-1">Campo 1</label>
        <input 
          type="text" 
          className="w-full px-3 py-2 border rounded-md"
          placeholder="Ingrese valor"
        />
      </div>
      <div>
        <label className="block text-sm font-medium mb-1">Campo 2</label>
        <input 
          type="text" 
          className="w-full px-3 py-2 border rounded-md"
          placeholder="Ingrese valor"
        />
      </div>
      <button className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
        Guardar
      </button>
    </form>
  </div>
);

const RightSidebar = ({ isOpen, onClose, selectedSubscription }) => {
  const renderContent = () => {
    switch (selectedSubscription.status) {
      case 'deal_pending':
        return <div className='px-6'>
        <DealPendingDetails subscription={selectedSubscription}/>

        </div>
      case 'canceled':
        return <div className='px-6'>
        <CancelledDetails subscription={selectedSubscription}/>
        </div>
      case 'active':
        return <div className='px-6'>
        <ActiveDetails subscription={selectedSubscription}/>
        </div>
      default:
        return <div className="p-6">Seleccione un estado válido</div>;
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Overlay with fade animation */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black/50 z-40"
            onClick={onClose}
          />
          
          {/* Sidebar with slide animation */}
          <motion.div 
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ 
              type: "spring",
              damping: 30,
              stiffness: 300
            }}
            className="fixed top-0 right-0 h-full 
                     min-w-[375px] max-w-[420px] w-full
                     bg-white shadow-xl z-50"
          >
            {/* Header */}
              <div className="px-6 py-4 flex justify-end itemscenter">

                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onClose}
                  className="p-1 rounded-full bg-gray-100 border-0 w-[40px] h-[40px] flex justify-center items-center"
                >
                  <X className="w-5 h-5" />
                </motion.button>
              </div>

            {/* Content */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="h-[calc(100%-65px)] overflow-y-auto"
            >
              {renderContent()}
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default RightSidebar;