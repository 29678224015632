import React from "react";

const SubscriptionRentedEquipment = () => {
  return (
    <div className="UserInfo self-stretch flex flex-col justify-start items-start gap-6">
      <div className="TitleTag self-stretch h-7 flex flex-col justify-start items-start gap-2.5">
        <div className="Cell px-2 py-1 bg-sky-100 flex justify-start items-center gap-4">
          <div className="Title text-neutral-800 text-base font-bold ">
            Equipo en renta
          </div>
        </div>
      </div>
      <div className="RefillStatus w-full flex justify-center items-center gap-4">
        <div className="flex bg-neutral-50 rounded-2xl flex-col justify-center items-center gap-2.5 w-full p-4 text-center">
          <img
            className="SquareImg rounded-lg max-h-[120px]"
            src="https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/dashboard-publics/dispenser-square-img.jpg"
          />
          <div className="SugActual flex-col justify-center items-center">
            <div className="Direccion text-black text-base font-semibold">
              3215-5621
            </div>
            <div className="DispensadorActual text-zinc-500 text-sm">
              Dispensador actual
            </div>
          </div>
        </div>
        <div className="flex     bg-neutral-50 rounded-2xl flex-col justify-center items-center gap-2.5 w-full p-4 text-center">
          <div className="bg-white rounded-lg flex gap-[8px]">
            <img
              className="SquareImg rounded-lg max-h-[120px]"
              src="https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/dashboard-publics/sug-square-img.jpg"
            />
          </div>
          <div className="SugActual  flex-col justify-center items-center">
            <div className="Direccion text-black text-base font-semibold">
              3215-5621
            </div>
            <div className="BotellNActual text-zinc-500 text-sm">
              Botellón actual
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionRentedEquipment;
