"use client";

import React, { useState, useEffect } from "react";
import { Calendar as CalendarIcon } from "lucide-react";
import DatePicker from "react-datepicker";
import { format, addDays, isSameDay, addDays as addDaysToDate } from "date-fns";
import { es } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerComponent({ setDeliveryDate, limitTodayTime }) {
  const today = new Date();
  const maxDate = addDays(today, 4);

  // Obtener la hora actual en Colombia
  const now = new Date();
  const colombiaOffset = -5; // UTC-5 para Colombia
  const colombiaTime = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours() + colombiaOffset,
    now.getUTCMinutes(),
    now.getUTCSeconds()
  );

  // Determinar si el día de hoy debe estar disponible
  const isTodayAvailable = colombiaTime.getHours() < limitTodayTime;

  // Seleccionar el primer día disponible automáticamente
  const initialDate = isTodayAvailable
    ? today
    : addDaysToDate(today, 1 + (today.getDay() === 6 ? 1 : 0)); // Saltar domingo si es sábado.

  const [selectedDate, setSelectedDate] = useState(initialDate);

  useEffect(() => {
    setDeliveryDate(selectedDate);
  }, [selectedDate, setDeliveryDate]);

  const formattedDate = format(selectedDate, "EEE yyyy/MMM/dd", {
    locale: es,
  }).toUpperCase();

  function handleDateChange(date) {
    setSelectedDate(date);
    setDeliveryDate(date);
  }

  return (
    <div className="flex items-center space-x-2">
      <CalendarIcon className="h-5 w-5 text-gray-500" />
      <DatePicker
        selected={selectedDate}
        onChange={(date) => handleDateChange(date)}
        customInput={<div>{formattedDate}</div>}
        dateFormat="EEE yyyy/MMM/dd"
        locale={es}
        filterDate={(date) => {
          const isInRange =
            isSameDay(date, today) || (date > today && date <= maxDate);
          const isNotSunday = date.getDay() !== 0;
          const canSelectToday =
            !(isSameDay(date, today) && !isTodayAvailable);

          return isInRange && isNotSunday && canSelectToday;
        }}
        className="text-md leading-4"
      />
    </div>
  );
}
