import React from "react";
import { Button } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { useSupaContext } from "../../../context/SupaContext";

const AdminProfile = () => {
  const { logout } = useSupaContext();
  const navigate = useNavigate();
  async function onLogout() {
    await logout();
    window.location.reload();
  }

  function goToLogin() {
    navigate("/ingresar");
  }
    
  return (
    <div className="px-8 md:px-0">
      <Button onClick={onLogout}>Cerrar sesión</Button>
    </div>
  );
};

export default AdminProfile;
