import React from "react";
import { getStateName, toMayus } from "../../../../../scripts/generalScript";

const SubscriptionDetailHeader = (props) => {
  return (
    <div className="flex w-full justify-between items-end">
      <div className="flex flex-col">
        <div className="Title text-neutral-800 text-xl font-medium ">
          Suscripción
        </div>
        <div className="text-black text-2xl font-bold px-2 py-1 bg-sky-100 flex justify-start items-center gap-4">
          {toMayus(props.subscription.shortid)}
        </div>
      </div>
      <div
        className={`SubscriptionStatus px-2 py-0.5 text-center text-neutral-50 text-lg font-bold 
              ${
                props.subscription.status === "canceled"
                  ? "bg-red-700"
                  : props.subscription.status === "deal_pending"
                  ? "bg-fuchsia-600"
                  : props.subscription.status === "active"
                  ? "bg-green-600"
                  : "bg-gray-800"
              } 
              rounded flex justify-center items-center gap-2.5`}
      >
        {getStateName(props.subscription.status)}
      </div>
    </div>
  );
};

export default SubscriptionDetailHeader;
