import React, { createContext, useContext, useState } from "react";
import supabase from "../SupaCredentials";

const SubscriptionsContext = createContext();

export const SubscriptionsProvider = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // --------------------------------------
  // Método para obtener suscripciones desde Supabase
  // --------------------------------------
  const fetchSubscriptions = async () => {
    setLoading(true);
    setError(null);

    try {
      const { data: subscriptions, error } = await supabase
        .from("subscriptions")
        .select("*");

      if (error) throw error;

      const enhancedSubscriptions = await Promise.all(
        subscriptions.map(async (subscription) => {
          // Obtener dirección
          const { data: addressData } = await supabase
            .from("addresses")
            .select("address")
            .eq("id", subscription.address_id)
            .single();

          // Obtener nombre de usuario
          let userData;
          if (
            subscription.status === "deal_pending" ||
            subscription.status === "canceled"
          ) {
            const { data } = await supabase
              .from("pre_registrations")
              .select("first_name, last_name, phone, email")
              .eq("id", subscription.user_id)
              .single();
            userData = data;
          } else {
            const { data } = await supabase
              .from("user_data")
              .select("first_name, last_name, phone, email")
              .eq("id", subscription.user_id)
              .single();
            userData = data;
          }

          // Obtener código de barras
          const { data: sugData } = await supabase
            .from("sugs_inventory")
            .select("codebar")
            .eq("id", subscription.current_sug_id)
            .single();

          return {
            ...subscription,
            address: addressData?.address,
            first_name: userData?.first_name,
            last_name: userData?.last_name,
            codebar: sugData?.codebar,
            phone: userData?.phone,
            email: userData?.email,
          };
        })
      );

      setSubscriptions(enhancedSubscriptions);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  // --------------------------------------
  // Método para obtener una orden por id
  // --------------------------------------
  const getOrderById = async (id) => {
    try {
      const { data, error } = await supabase
      .from("orders")
      .select("*")
        .eq("id", id);

      if (error) throw error;
      return data; // Retorna los datos de la orden
    } catch (error) {
      console.error("Error obteniendo la orden:", error.message);
      return null; // Retorna null si hay error
    }
  };

  // --------------------------------------
  //Metodo para obtener los items de unaorden organizados
  // --------------------------------------
  const processOrderItems = async (orderId) => {
    try {
      const { data: orderItems, error } = await supabase
        .from("order_items")
        .select("*")
        .eq("order_id", orderId);

      if (error) throw error;

      const result = {
        orderItems: [],
        summary: {
          totalAmount: 0,
          totalItems: 0,
          uniqueItems: 0,
          byType: {}, // Nuevo objeto para almacenar totales por tipo
        },
      };

      const groupedItems = {};

      // Procesar y agrupar items
      orderItems.forEach((item) => {
        const key = `${item.item_type}_${item.item_id}`;

        if (groupedItems[key]) {
          groupedItems[key].qty += item.qty;
          groupedItems[key].totalValue += Number(item.value) * item.qty;
        } else {
          groupedItems[key] = {
            id: item.id,
            item_type: item.item_type,
            item_id: item.item_id,
            qty: item.qty,
            unitValue: Number(item.value),
            totalValue: Number(item.value) * item.qty,
          };
        }

        // Procesar totales por tipo
        if (!result.summary.byType[item.item_type]) {
          result.summary.byType[item.item_type] = {
            qty: 0,
            totalValue: 0,
            items: 0, // Contador de items únicos de este tipo
          };
        }
      });

      // Convertir el objeto agrupado en array
      result.orderItems = Object.values(groupedItems);

      // Calcular totales incluyendo el desglose por tipo
      result.orderItems.forEach((item) => {
        result.summary.byType[item.item_type].qty += item.qty;
        result.summary.byType[item.item_type].totalValue += item.totalValue;
        result.summary.byType[item.item_type].items += 1;
      });

      // Calcular totales generales
      result.summary.uniqueItems = result.orderItems.length;
      result.summary.totalAmount = result.orderItems.reduce(
        (sum, item) => sum + item.totalValue,
        0
      );
      result.summary.totalItems = result.orderItems.reduce(
        (sum, item) => sum + item.qty,
        0
      );

      return result;
    } catch (error) {
      console.error("Error processing order items:", error);
      throw error;
    }
  };

  // --------------------------------------
  // Método para cancelar ordenes
  // --------------------------------------
  const cancelOrder = async (orderId, reason) => {
    try {
      // Iniciar una transacción para asegurar que ambos cambios se realicen juntos
      const { error: updateError } = await supabase
        .from("orders")
        .update({ status: "canceled" })
        .eq("id", orderId);

      if (updateError) {
        throw new Error(`Error updating order status: ${updateError.message}`);
      }

      const { error: insertError } = await supabase
        .from("orders_cancellations")
        .insert([{ order_id: orderId, reason }]);

      if (insertError) {
        throw new Error(
          `Error inserting into orders_cancellations: ${insertError.message}`
        );
      }

      console.log("Order canceled successfully");
    } catch (error) {
      console.error("Error cancelling order:", error.message);
    }
  };

  
  // --------------------------------------
  // Método para cancelar suscripciones por order_id 
  // --------------------------------------
  const cancelSubscriptionsByOrder = async (orderId) => {
    try {
      const { error } = await supabase
        .from("subscriptions")
        .update({ status: "canceled" })
        .eq("order_id", orderId)
      if (error) {
        throw new Error(`Error cancelling subscriptions: ${error.message}`);
      }

      console.log(
        `Subscriptions with order_id ${orderId} and status 'deal_pending' canceled successfully.`
      );
    } catch (error) {
      console.error("Error in cancelSubscriptionsByOrder:", error.message);
    }
  };

  // --------------------------------------
  // Método para cancelar múltiples suscripciones
  // --------------------------------------
  const cancelMultipleSubscriptions = async (subscriptionIds) => {
    try {
      const promises = subscriptionIds.map((id) =>
        cancelSubscriptionsByOrder(id)
      );
      await Promise.all(promises);

      console.log("All subscriptions canceled successfully.");
    } catch (error) {
      console.error("Error in cancelMultipleSubscriptions:", error.message);
    }
  };

  // --------------------------------------
  // Método para cancelar suscripciones por order_id con status 'deal_pending'
  // --------------------------------------
  const cancelRefillsByOrder = async (orderId) => {
    try {
      const { data, error } = await supabase
        .from("refills")
        .update({ status: "canceled" })
        .eq("order_id", orderId)
        .eq("status", "deal_pending");

      if (error) {
        throw new Error(`Error al actualizar los estados: ${error.message}`);
      }

      if (data.length === 0) {
        return { message: "No se encontraron registros para cancelar.", data };
      }

      return {
        message: "Todos los refills con deal_pending fueron cancelados.",
        data,
      };
    } catch (error) {
      console.error("Error:", error.message);
      return { message: error.message };
    }
  };

  // --------------------------------------
  // Método para activar ordenes
  // --------------------------------------
  const activeOrder = async (orderId) => {
    try {
      // Iniciar una transacción para asegurar que ambos cambios se realicen juntos
      const { error: updateError } = await supabase
        .from("orders")
        .update({ status: "completed" })
        .eq("id", orderId);

      if (updateError) {
        throw new Error(`Error updating order status: ${updateError.message}`);
      }

      console.log("Order completed successfully");
    } catch (error) {
      console.error("Error completing order:", error.message);
    }
  };

    // --------------------------------------
  // Método para activar suscripciones por order_id 
  // --------------------------------------
  const activeSubscriptionsByOrder = async (orderId) => {
    try {
      const { error } = await supabase
        .from("subscriptions")
        .update({ status: "active" })
        .eq("order_id", orderId)
      if (error) {
        throw new Error(`Error cancelling subscriptions: ${error.message}`);
      }

      console.log(
        `Subscriptions with order_id ${orderId} and status activated successfully.`
      );
    } catch (error) {
      console.error("Error in activeSubscriptionsByOrder:", error.message);
    }
  };

  // --------------------------------------
  // Método para cancelar suscripciones por order_id con status 'deal_pending'
  // --------------------------------------
  const activeRefillsByOrder = async (orderId) => {
    try {
      const { data, error } = await supabase
        .from("refills")
        .update({ status: "active" })
        .eq("order_id", orderId)

      if (error) {
        throw new Error(`Error al actualizar los estados: ${error.message}`);
      }

      if (data === null) {
        return { message: "No se encontraron registros para activar.", data };
      }

      return {
        message: "Todos los refills fueron activados.",
        data,
      };
    } catch (error) {
      console.error("Error:", error.message);
      return { message: error.message };
    }
  };


  return (
    <SubscriptionsContext.Provider
      value={{
        subscriptions,
        loading,
        error,
        fetchSubscriptions,
        getOrderById,
        processOrderItems,
        cancelOrder,
        cancelSubscriptionsByOrder,
        cancelMultipleSubscriptions,
        cancelRefillsByOrder,
        activeOrder,
        activeSubscriptionsByOrder,
        activeRefillsByOrder
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};

// Hook personalizado para usar el contexto en otros componentes
export const useSubscriptions = () => useContext(SubscriptionsContext);
