import React from 'react'

const AdminOrders = () => {
  return (
    <div>
      AdminOrders
    </div>
  )
}

export default AdminOrders
