import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import supabase from "../SupaCredentials";
import { generatePassword } from "../scripts/generalScript";

export const SupaContext = createContext();
export const useSupaContext = () => useContext(SupaContext);

export const SupaProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const [isActiveUser, setIsActiveUser] = useState(false);

  // --------------------------------------
  // Función de Pre-Registro de Usuario
  // --------------------------------------

  // --------------------------------------
  // Inicio de Sesión
  // --------------------------------------
  const login = useCallback(async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      console.log("Login exitoso:", data.user);
      setUser(data.user);
      setIsActiveUser(true);
      return data.user;
    } catch (error) {
      console.error("Error al iniciar sesión:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  // --------------------------------------
  // Cierre de Sesión
  // --------------------------------------
  const logout = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser({});
      setIsActiveUser(false);
    } catch (error) {
      console.error("Error al cerrar sesión:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  // --------------------------------------
  // Manejo del Estado de Autenticación
  // --------------------------------------
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user || null);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  // --------------------------------------
  // Función para crear una dirección
  // --------------------------------------
  const createAddressAfterCreateUser = async (addressData) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("addresses")
        .insert([
          {
            user_id: addressData.user_id,
            city: addressData.city,
            state: addressData.state,
            country: addressData.country,
            latitude: addressData.latitude,
            longitude: addressData.longitude,
            address: addressData.address,
            details: addressData.details,
            pre_registration: addressData.pre_registration,
          },
        ])
        .select();
      if (error) throw error;
      return data[0];
    } catch (error) {
      console.error("Error al crear la dirección:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Función para agregar un nuevo pre_registration
  // --------------------------------------

  const addPreRegistration = async (preRegistrationData) => {
    // Primero, intentamos encontrar un usuario existente
    const { data: existingUser, error: searchError } = await supabase
      .from("pre_registrations")
      .select("id")
      .eq("email", preRegistrationData.email)
      .single();

    if (searchError && searchError.code !== "PGRST116") {
      console.error("Error al buscar usuario existente:", searchError.message);
      return null;
    }

    if (existingUser) {
      console.log("Usuario existente encontrado:", existingUser);
      return existingUser; // Devuelve el usuario existente con su ID
    }

    // Si no existe, procedemos con el pre-registro
    const { data, error } = await supabase
      .from("pre_registrations")
      .insert([preRegistrationData])
      .select();

    if (error) {
      console.error("Error al insertar pre-registro:", error.message);
      return null;
    }

    console.log("Pre-registro creado:", data[0]);
    return data[0]; // Devuelve el objeto con el ID recién creado
  };

  // --------------------------------------
  // Funcion para crear una suscripcion
  // --------------------------------------
  const createSubscription = async (subscriptionData) => {
    try {
      const { userId, currentSugId, addressId } = subscriptionData;

      // Verificar si el usuario existe en pre_registrations
      const { data: userExists, error: userCheckError } = await supabase
        .from("pre_registrations")
        .select("id")
        .eq("id", userId)
        .single();

      if (userCheckError) {
        console.error("Error checking user existence:", userCheckError);
        throw new Error("Failed to verify user existence");
      }

      if (!userExists) {
        throw new Error("User ID does not exist in pre_registrations");
      }

      const startDate = new Date();
      const nextRenewalDate = new Date(startDate);
      nextRenewalDate.setMonth(nextRenewalDate.getMonth() + 1);

      const nextBottleChangeDate = new Date(startDate);
      nextBottleChangeDate.setMonth(nextBottleChangeDate.getMonth() + 3);

      const { data, error } = await supabase
        .from("subscriptions")
        .insert([
          {
            user_id: userId,
            current_sug_id: currentSugId,
            original_sug_id: currentSugId,
            start_date: startDate.toISOString(),
            status: "deal_pending",
            address_id: addressId,
          },
        ])
        .select();

      if (error) throw error;

      return data[0];
    } catch (error) {
      console.error("Error adding subscription:", error);
      throw error;
    }
  };

  // --------------------------------------
  // Funcion para creal multiples suscripciones
  // --------------------------------------
  const createSubscriptionsForSugs = async (sugs, baseData) => {
    for (const sug of sugs) {
      const subscriptionData = {
        ...baseData,
        currentSugId: sug.id, // Make sure this matches the property name in createSubscription
      };
      try {
        const result = await createSubscription(subscriptionData);
      } catch (error) {
        console.error("Error al crear la suscripción para", sug.id, error);
      }
    }
    return true;
  };

  // --------------------------------------
  // Función para crear una orden
  // --------------------------------------
  const createOrder = async (orderData) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("orders")
        .insert([
          {
            user_id: orderData.userId,
            public_id: orderData.public_id,
            total: orderData.total,
            terms: true,
            status: "pending",
          },
        ])
        .select();

      if (error) throw error;
      return data[0];
    } catch (error) {
      console.error("Error al crear la orden:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Función para crear un solo item en una orden
  // --------------------------------------
  const createOrderItem = async (item) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("order_items")
        .insert({
          order_id: item.order_id,
          order_public_id: item.order_public_id,
          item_type: item.type || item.item_type,
          item_id: item.id || item.item_id,
          qty: item.qty,
          value: item.value,
        })
        .select();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error al crear los items de la orden:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Crear multiples items usando la funcion anterior
  // --------------------------------------

  const createMultipleOrderItems = async (baseItem, itemIds) => {
    setLoading(true);
    setError(null);
    const results = [];
    const errors = [];

    try {
      // Creamos un array de promesas para procesar todos los items en paralelo
      const itemPromises = itemIds.map(async (itemId) => {
        // Combinamos la base con el id específico
        const newItem = {
          ...baseItem,
          item_id: itemId,
        };

        try {
          const result = await createOrderItem(newItem);
          if (result) {
            results.push(result);
          }
        } catch (error) {
          errors.push({ itemId, error: error.message });
        }
      });

      // Esperamos a que todas las promesas se resuelvan
      await Promise.all(itemPromises);

      // Si hay errores pero también hay resultados exitosos, continuamos pero guardamos los errores
      if (errors.length > 0) {
        console.warn("Algunos items no pudieron ser creados:", errors);
        setError(`${errors.length} items fallaron al crearse`);
      }

      return {
        success: results.length > 0,
        data: results,
        errors: errors.length > 0 ? errors : null,
      };
    } catch (error) {
      console.error("Error general al crear los items:", error.message);
      setError(error.message);
      return {
        success: false,
        data: null,
        errors: [error.message],
      };
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Valores del Contexto
  // --------------------------------------

  const createOrderItemsWithSugs = async (order, sugs, baseItemData) => {
    setLoading(true);
    setError(null);
    try {
      // Validaciones mejoradas según la estructura de la tabla
      if (!order?.id || !order?.public_id) {
        throw new Error("Se requiere order.id y order.public_id");
      }

      if (!sugs || !Array.isArray(sugs) || sugs.length === 0) {
        throw new Error("Se requiere un array válido de sugs");
      }

      if (!baseItemData?.item_type || typeof baseItemData.value !== "number") {
        throw new Error("baseItemData debe incluir item_type y value numérico");
      }

      // Preparar los items según la estructura exacta de la tabla
      const items = sugs.map((sug) => ({
        order_id: order.id,
        order_public_id: order.public_id,
        item_type: baseItemData.item_type,
        item_id: sug.id,
        qty: baseItemData.qty || 1,
        value: Number(baseItemData.value), // Aseguramos que sea numérico
      }));

      const { data, error } = await supabase
        .from("order_items")
        .insert(items)
        .select();

      if (error) {
        console.error("Error en Supabase:", error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error("Error al crear order items:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Agregar recambios
  // --------------------------------------
  const createRefill = async (refillData) => {
    try {
      const { data, error } = await supabase
        .from("refills")
        .insert([
          {
            user_id: refillData.userId,
            order_id: refillData.orderId,
            status: refillData.status || "deal_pending",
            purchase_date: refillData.purchaseDate || new Date().toISOString(),
            used_date: refillData.usedDate || null,
            used_subscription: refillData.usedSubscription || null,
          },
        ])
        .select();

      if (error) throw error;
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  // --------------------------------------
  // AGREGAR VARIOS RECAMBIOS EN AUTO
  // --------------------------------------

  const createBulkRefills = async (qty, userId, orderId) => {
    try {
      // Creamos un array con la cantidad especificada de objetos idénticos
      const refills = Array(qty).fill({
        user_id: userId,
        order_id: orderId,
        status: "deal_pending",
      });

      const { data, error } = await supabase
        .from("refills")
        .insert(refills)
        .select("id");

      if (error) throw error;

      return {
        success: true,
        count: qty,
        refillIds: data.map((refill) => refill.id),
        // Incluimos también el array completo de datos por si es necesario
        data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
        refillIds: [],
        count: 0,
      };
    }
  };

  // --------------------------------------
  // TRAER SUSCRIPCIONES
  // --------------------------------------
  const fetchSubscriptions = useCallback(async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Primero, obtener todas las suscripciones
      const { data: subscriptionsData, error: subscriptionsError } = await supabase
        .from("subscriptions")
        .select('*, current_sug_id(id, codebar, user_)')
        .order("created_at", { ascending: false });
      
      if (subscriptionsError) throw subscriptionsError;
      
      // Depuración: imprimir los IDs de usuario
      console.log("Subscription User IDs:", subscriptionsData.map(sub => sub.user_id));
      
      // Separar suscripciones por estado
      const dealPendingIds = subscriptionsData
      .filter(sub => sub.status === "deal_pending")
      .map(sub => sub.user_id);
      console.log('dealPendingIds: ', dealPendingIds);
      
      const nonDealPendingIds = subscriptionsData
      .filter(sub => sub.status !== "deal_pending")
      .map(sub => sub.user_id);
      console.log('nonDealPendingIds: ', nonDealPendingIds);
      
      // Consulta de usuarios para non-deal_pending
      const userDataQuery = async () => {
        // Si no hay IDs, retornar un arreglo vacío
        if (nonDealPendingIds.length === 0) return [];
        
        try {
          const { data, error } = await supabase
            .from("user_data")
            .select('*')
            .in('id', nonDealPendingIds);
          
          if (error) {
            console.error("Error fetching user data:", error);
            return [];
          }
          
          console.log("Fetched User Data:", data);
          return data;
        } catch (error) {
          console.error("Catch block error in user data fetch:", error);
          return [];
        }
      };
      
      // Consulta de pre-registrations
      const preRegistrationsQuery = async () => {
        // Si no hay IDs, retornar un arreglo vacío
        if (dealPendingIds.length === 0) return [];
        
        try {
          const { data, error } = await supabase
            .from("pre_registrations")
            .select('*')
            .in('id', dealPendingIds);
          
          if (error) {
            console.error("Error fetching pre-registrations:", error);
            return [];
          }
          
          console.log("Fetched Pre-Registrations:", data);
          return data;
        } catch (error) {
          console.error("Catch block error in pre-registrations fetch:", error);
          return [];
        }
      };
      
      // Ejecutar ambas consultas en paralelo
      const [userData, preRegistrationsData] = await Promise.all([
        userDataQuery(),
        preRegistrationsQuery()
      ]);
      
      // Procesar los datos combinando la información
      const processedData = subscriptionsData.map(subscription => {
        let userInfo;
        
        if (subscription.status === "deal_pending") {
          userInfo = preRegistrationsData.find(pre => pre.id === subscription.user_id);
        } else {
          userInfo = userData.find(user => user.id === subscription.user_id);
        }
        
        return {
          ...subscription,
          user_info: userInfo || null
        };
      });
      
      return processedData;
    } catch (error) {
      console.error("Error fetching subscriptions:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  // --------------------------------------
  // New method to calculate subscription stats
  // --------------------------------------
  const calculateSubscriptionStats = useCallback((subscriptions) => {
    const stats = {
      inactive: 0,
      pending: 0,
      active: 0,
      returning: 0,
      canceled: 0,
    };

    subscriptions.forEach((sub) => {
      switch (sub.status) {
        case "deal_pending":
          stats.inactive++;
          break;
        case "pending_renewal":
          stats.pending++;
          break;
        case "active":
          stats.active++;
          break;
        case "returning":
          stats.returning++;
          break;
        case "canceled":
          stats.canceled++;
          break;
      }
    });

    return stats;
  }, []);

  // --------------------------------------
  // Valores del Contexto
  // --------------------------------------
  async function getPublicFileUrl(fileName) {
    if (!fileName) return ""; // Si el nombre del archivo está vacío, retorna ""
    const fullFileName = fileName + ".png";

    try {
      // Intenta obtener la URL pública
      const { data, error } = supabase.storage
        .from("addresses_captures")
        .getPublicUrl(fullFileName);

      if (error) {
        console.error("Error obteniendo la URL pública:", error.message);
        return ""; // Si hay un error, retorna una cadena vacía
      }

      return data.publicUrl || ""; // Si no hay error, retorna la URL o una cadena vacía si no se encuentra
    } catch (err) {
      console.error("Error inesperado:", err);
      return ""; // Captura cualquier otro error
    }
  }

  // --------------------------------------
  // Mover preregistro a usuario y crear usuario
  // --------------------------------------
  async function migratePreRegistrationToUser(preRegistrationId) {
    console.log("preRegistrationId: ", preRegistrationId);
    try {
      // Obtener datos del pre-registro
      const { data: preRegData, error: preRegError } = await supabase
        .from("pre_registrations")
        .select("*")
        .eq("id", preRegistrationId)
        .single();
  
      if (preRegError || !preRegData)
        throw preRegError || new Error("Pre-registro no encontrado");
      console.log("preRegData: ", preRegData);
  
      // Insertar en user_data y eliminar pre-registro en una transacción
      const { data, error } = await supabase
        .from("user_data")
        .insert({
          id:preRegData.id,
          name: preRegData.first_name,
          last_name: preRegData.last_name,
          phone: preRegData.phone,
          email: preRegData.email,
          has_account: false,
          coming_from: "pre_registration",
        })
        .select();
  
      if (error) throw error;
  
      // Eliminar pre-registro
      await supabase
        .from("pre_registrations")
        .delete()
        .eq("id", preRegistrationId);
  
      return { success: true, userData: data[0] };
    } catch (error) {
      console.error("Error en la migración:", error);
      return { success: false, error };
    }
  }

  // --------------------------------------
  // Valores del Contexto
  // --------------------------------------
  const contextValue = {
    migratePreRegistrationToUser,
    getPublicFileUrl,
    user,
    login,
    logout,
    loading,
    isActiveUser,
    error,
    createAddressAfterCreateUser,
    addPreRegistration,
    createSubscription,
    createSubscriptionsForSugs,
    createOrder,
    createOrderItem,
    createMultipleOrderItems,
    createOrderItemsWithSugs,
    createRefill,
    createBulkRefills,
    fetchSubscriptions,
    calculateSubscriptionStats,
  };

  return (
    <SupaContext.Provider value={contextValue}>{children}</SupaContext.Provider>
  );
};
