"use client";

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import {
  X,
  Receipt,
  ArrowLeftCircle,
  FileX2,
  Calendar,
  Bike,
} from "lucide-react";

import DealPendingActions from "../dashboard/components-dashboard/subscriptions/DealPendingActions";

const DialogContainer = ({
  type = "cancel",
  isOpen,
  onClose,
  subscription,
  orderData = {
    id: "FG52-DSDZ",
    customer: "Ramon S.",
    address: "Carrera 11 # 98-85 Apt",
    items: [
      { type: "Suscripciones", quantity: 3, price: 9990 },
      { type: "Recambios", quantity: 8, price: 13990 },
    ],
  },
}) => {
  // Removed unused state variables

  return (
    <div>
      <Dialog.Root open={isOpen} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay z-[9999]" />
          <Dialog.Content className="DialogContent rounded-3xl border-">
            <div className="flex flex-col h-full">
              {/* Header */}
              <Dialog.Title />
              <div className="flex items-center justify-between pb-4">
                <div className="flex items-center gap-2">
                  <div
                    className={`w-5 h-5 rounded ${
                      type === "cancel" ? "bg-red-700" : "bg-green-600"
                    }`}
                  />
                  <h2 className="text-xl font-medium text-neutral-800">
                    {type === "cancel"
                      ? "Cancelar suscripción"
                      : "Activar suscripción"}
                  </h2>
                </div>
                <Dialog.Close className="w-8 h-8 flex items-center justify-center rounded-full bg-zinc-100 border-0">
                  <X className="w-5 h-5" />
                </Dialog.Close>
              </div>
              <Dialog.Description />
              <DealPendingActions
                type={type}
                orderDataDummy={orderData}
                onClose={onClose}
                subscription={subscription}
              />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default DialogContainer;
