import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import "../../styles/DialogStyles.css";

const stateStyles = {
  default: "bg-blue-800 hover:bg-blue-600 text-white w-full",
  success: "bg-green-500 hover:bg-green-600 text-white w-full",
  warning: "bg-yellow-500 hover:bg-yellow-600 text-black w-full",
  error: "bg-red-500 hover:bg-red-600 text-white w-full",
  disabled: "bg-gray-300 text-gray-500 cursor-not-allowed w-full"
};

export default function ConfirmationButton({ 
  initialLabel = "Presionar", 
  onConfirm, 
  loadingTime = 2000, 
  state = "default",
  disabled = false
}) {
  const [buttonState, setButtonState] = useState('initial');
  const [label, setLabel] = useState(initialLabel);
  const [progress, setProgress] = useState(0);
  const timerRef = useRef(null);
  const resetTimerRef = useRef(null);
  const progressIntervalRef = useRef(null);

  const resetToInitial = useCallback(() => {
    // Solo reseteamos si no estamos en estado loading
    if (buttonState !== 'loading') {
      setButtonState('initial');
      setLabel(initialLabel);
      setProgress(0);
    }
    clearTimeout(timerRef.current);
    clearTimeout(resetTimerRef.current);
    clearInterval(progressIntervalRef.current);
  }, [initialLabel, buttonState]);

  const handleInitialClick = useCallback(() => {
    if (disabled) return;
    setButtonState('revealed');
    setLabel("Presiona 2s para confirmar");
    resetTimerRef.current = setTimeout(resetToInitial, 2500);
  }, [resetToInitial, disabled]);

  const handleConfirmMouseDown = useCallback(() => {
    if (disabled) return;
    clearTimeout(resetTimerRef.current);
    setProgress(0);
    
    progressIntervalRef.current = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(progressIntervalRef.current);
          return 100;
        }
        return prev + 5;
      });
    }, 100);

    timerRef.current = setTimeout(() => {
      clearInterval(progressIntervalRef.current);
      setProgress(100);
      setButtonState('loading');
      setLabel("Cargando");
      
      // Llamamos a onConfirm sin resetear después
      try {
        onConfirm();
      } catch (error) {
        // Solo reseteamos si hay un error
        console.error('Error during confirmation:', error);
        setButtonState('initial');
        setLabel(initialLabel);
        setProgress(0);
      }
    }, 2000);
  }, [onConfirm, initialLabel, disabled]);

  const handleConfirmMouseUp = useCallback(() => {
    if (disabled) return;
    if (buttonState !== 'loading') {
      clearTimeout(timerRef.current);
      clearInterval(progressIntervalRef.current);
      setProgress(0);
    }
  }, [buttonState, disabled]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(resetTimerRef.current);
      clearInterval(progressIntervalRef.current);
    };
  }, []);

  const getButtonClass = useCallback(() => {
    if (disabled) return stateStyles.disabled;
    return stateStyles[state] || stateStyles.default;
  }, [state, disabled]);

  const getProgressColor = useCallback(() => {
    const buttonClass = getButtonClass();
    if (buttonClass.includes('blue')) return 'bg-blue-900';
    if (buttonClass.includes('green')) return 'bg-green-700';
    if (buttonClass.includes('yellow')) return 'bg-yellow-700';
    if (buttonClass.includes('red')) return 'bg-red-700';
    return 'bg-gray-400';
  }, [getButtonClass]);

  const renderButton = () => {
    const baseClassName = `relative overflow-hidden px-4 py-2 rounded-xl flex justify-center items-center transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 ${getButtonClass()}`;

    const progressBar = buttonState !== 'initial' && (
      <div 
        className={`absolute bottom-0 left-0 h-1 transition-all duration-100 ${getProgressColor()}`}
        style={{ width: `${progress}%` }}
      />
    );

    switch (buttonState) {
      case 'initial':
        return (
          <button
            onClick={handleInitialClick}
            className={baseClassName}
            disabled={disabled}
          >
            {label}
            {progressBar}
          </button>
        );
      case 'revealed':
        return (
          <button
            onMouseDown={handleConfirmMouseDown}
            onMouseUp={handleConfirmMouseUp}
            onMouseLeave={handleConfirmMouseUp}
            className={baseClassName}
            disabled={disabled}
          >
            {label}
            {progressBar}
          </button>
        );
      case 'loading':
        return (
          <button disabled className={`${baseClassName} cursor-not-allowed`}>
            <Loader2 className="inline-block mr-2 h-4 w-4 animate-spin" />
            {label}
            {progressBar}
          </button>
        );
      default:
        return null;
    }
  };

  return renderButton();
}