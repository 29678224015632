import React from "react";

const SubscriptionRefills = (props) => {
  return (
    <div className="UserInfo self-stretch h-40 flex flex-col justify-start items-start gap-6">
      <div className="TitleTag self-stretch h-7 flex flex-col justify-start items-start gap-2.5">
        <div className="Cell px-2 py-1 bg-sky-100 flex justify-start items-center gap-4">
          <div className="Title text-neutral-800 text-base font-bold ">
            Recambios
          </div>
        </div>
      </div>
      <div className="UserInfo self-stretch h-24 flex flex-col justify-start items-start gap-1">
        <div className="User self-stretch py-1 flex justify-between items-center">
          <div className="Cliente text-zinc-500 text-md font-normal ">
            Recambio en curso
          </div>
          {/* Nombre del cliente */}
          <div className="Cliente text-black text-md font-semibold ">
            {3}
          </div>
        </div>
        <div className="Mail self-stretch py-1 flex justify-between items-center">
          <div className="Email text-zinc-500 text-md font-normal ">
            Recambios realizados
          </div>
          {/* Código de barras */}
          <div className="Cliente text-black text-md font-semibold ">
            {3}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionRefills;
