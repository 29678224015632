"use client";
import { Button, Badge } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import {
  toMayus,
  getStateName,
  timeSince,
  formatDateTime,
} from "../../../../scripts/generalScript";
import DialogContainer from "../../../ui/DialogContainer";
import SubscriptionDetailHeader from "./cross/SubscriptionDetailHeader";
import SubscriptionAddressInfo from "./cross/SubscriptionAddressInfo";
import SubscriptionDetailUser from "./cross/SubscriptionDetailUser";

const CancelledDetails = ({ subscription }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogType, setDialogType] = useState("cancel");

  const openDialog = (type) => {
    setDialogType(type);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  // Ejecuta solo si id y requestDate existen
  useEffect(() => {
    if (subscription.id && subscription.start_date) return;
  }, [subscription]);

  const orderData = {
    id: subscription.shortid,
    customer: `${subscription.first_name} ${subscription.last_name}`,
    address: subscription.address,
    items: [
      { type: "Suscripción", quantity: 1, price: subscription.price || 0 },
    ],
  };

  return (
    <div className="StatusDealPending bg-white flex flex-col justify-start items-start">
      <SubscriptionDetailHeader
        subscription={subscription}
      ></SubscriptionDetailHeader>

      <div className="DividerWithMargin self-stretch py-6 flex flex-col justify-start items-start gap-2.5">
        <div className="Divider self-stretch h-0.5 bg-zinc-100 rounded-lg" />
      </div>
      {/* Detalles de Suscripción */}
      <div className="SubscriptionsDetails self-stretch flex flex-col justify-start items-start gap-1">
        <div className="Cell self-stretch py-1 flex justify-between items-center">
          <div className="FechaDeSolicitud text-zinc-500 text-md font-normal ">
            Fecha de inicio:
          </div>
          <div className="Renovation flex justify-end items-center gap-2">
            {/* Fecha de inicio */}
            <div className="Date text-black text-md font-normal ">
              {formatDateTime(subscription.start_date)}
            </div>
            <Badge color="gray">{timeSince(subscription.start_date)}</Badge>
          </div>
        </div>
        <div className="Cell self-stretch py-1 flex justify-between items-center">
          <div className="FechaDeSolicitud text-zinc-500 text-md font-normal ">
            Cancelación
          </div>
          <div className="Renovation flex justify-end items-center gap-2">
            {/* Fecha de inicio */}
            <div className="Date text-black text-md font-normal ">
              {formatDateTime(subscription.start_date)}
            </div>
            <Badge color="gray">{timeSince(subscription.start_date)}</Badge>
          </div>
        </div>
      </div>

      {/* Acciones de Suscripción */}
      <div className="DividerWithMargin self-stretch py-6 flex flex-col justify-start items-start gap-2.5">
        <div className="Divider self-stretch h-0.5 bg-zinc-100 rounded-lg" />
      </div>
      <div className="SubscriptionActions flex justify-around w-full">
        <Button size="3" width="100%" onClick={() => openDialog("activate")}>
          Crear nueva suscripción identica
        </Button>
      </div>
      <div className="DividerWithMargin self-stretch py-6 flex flex-col justify-start items-start gap-2.5">
        <div className="Divider self-stretch h-0.5 bg-zinc-100 rounded-lg" />
      </div>

      {/* Información del Cliente */}
      <SubscriptionDetailUser
        subscription={subscription}
      ></SubscriptionDetailUser>

      <div className="DividerWithMargin self-stretch py-6 flex flex-col justify-start items-start gap-2.5">
        <div className="Divider self-stretch h-0.5 bg-zinc-100 rounded-lg" />
      </div>

      {/* Información de la Dirección */}
      <SubscriptionAddressInfo
        subscription={subscription}
      ></SubscriptionAddressInfo>

      {/* SubscriptionDialog */}
      <DialogContainer
        type={dialogType}
        isOpen={isOpen}
        onClose={closeDialog}
        orderData={orderData}
        subscription={subscription}
      />
    </div>
  );
};

export default CancelledDetails;
