'use client'

import React, { useState, useEffect } from "react"
import { Menu, X } from "lucide-react"
import {
  useLocation,
  Routes,
  Route,
} from "react-router-dom"


import AdminResume from "./pages-dashboard/AdminResume"
import AdminOperations from "./pages-dashboard/AdminOperations"
import AdminSusciptions from "./pages-dashboard/AdminSusciptions"
import AdminOrders from "./pages-dashboard/AdminOrders"
import AdminProfile from "./pages-dashboard/AdminProfile"
import DashboardSidepanel from "./DashboardSidepanel"

const menuItems = [
  { path: "/dashboard", label: "Resumen" },
  { path: "/dashboard/suscripciones", label: "Suscripciones" },
  { path: "/dashboard/pedidos", label: "Pedidos y entregas" },
  { path: "/dashboard/ops", label: "Operación" },
  { path: "/dashboard/clientes", label: "Clients" },
  { path: "/dashboard/inventario", label: "Inventario" },
  { path: "/dashboard/proveedores", label: "Proveedores" },
  { path: "/dashboard/plantas", label: "Plantas" },
  { path: "/dashboard/finanzas", label: "Finanzas" },
  { path: "/dashboard/ajustes", label: "Ajustes" },
  { path: "/dashboard/cuenta", label: "Cuenta de usuario" },
]

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
      if (window.innerWidth >= 768) {
        setSidebarOpen(true)
      } else {
        setSidebarOpen(false)
      }
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)

  return (
    <div className="relative flex h-screen overflow-hidden">
      {/* Overlay */}
      {isMobile && sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleSidebar}
        />
      )}

      <DashboardSidepanel 
        isMobile={isMobile}
        sidebarOpen={sidebarOpen}
        onMobileClick={() => isMobile && setSidebarOpen(false)}
      />

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center">
            {isMobile && (
              <button
                onClick={toggleSidebar}
                className="mr-4 text-gray-600 hover:text-gray-900"
              >
                {sidebarOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            )}
            <h1 className="text-2xl font-normal text-gray-900">
              {menuItems.find(item => item.path === location.pathname)?.label || "Dashboard"}
            </h1>
          </div>
        </header>

        <main className="flex-1 overflow-auto bg-gray-50">
          <div className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
            <Routes>
              <Route index element={<AdminResume />} />
              <Route path="/ops" element={<AdminOperations />} />
              <Route path="/suscripciones" element={<AdminSusciptions />} />
              <Route path="/suscripciones/:shortId" element={<AdminSusciptions />} />
              <Route path="/pedidos" element={<AdminOrders />} />
              <Route path="/cuenta" element={<AdminProfile />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Dashboard